<template>
    <div>
        <div class="container-top">
            <img class="container_backgroundImg" src="../assets/img/carHead.png">
            <div class="container-top-content">
                <div class="content-list">
                    <img :src="avatar" class="content-list-img">
                </div>
                <div class="content-list">
                    <span class="content-list-greet">嗨，{{time}}～</span>
                </div>
                <div class="content-list">
                    <span class="content-list-title">道路千万条，安全第一条</span>
                </div>
            </div>
        </div>
        <div class="tab-img">
            <img class="container-main-img" src="../assets/img/tab_bg.png">
        </div>
        <div class="container-main-card">
            <div class="main-card" v-for="(item,index) in list" :key="index">
                <img class="main-card-img" src="../assets/img/carCard.png">
                <div class="main-card-container">
                    <div class="main-card-head" @click="longPayRecord(item)">
                        <div class="main-card-head-font">
                            <div class="main-card-head-title">
                                <img src="../assets/img/card.png" class="haed-card-img">
                                {{ item.parkingName }} · {{ item.cycleType == 1 ? '月卡' : item.cycleType == 2 ? '季卡' :
                                item.cycleType == 3 ? '半年卡' : item.cycleType == 4 ? '年卡': item.cycleType == 5 ? '长期卡' :
                                ''}}
                            </div>
                        </div>
                        <div class="main-card-head-time">
                            <span class="main-card-head-time-font">有效期至{{item.dueTime}}</span>
                        </div>
                        <div class="placeholder-div"></div>
                    </div>
                    <div class="main-card-middle" @click="longPayRecord(item)">
                        <span class="main-card-middle-font">车位编号 {{item.parkingPlaceNumber}}</span>
                    </div>
                    <div class="main-card-bottom">
                        <div class="main-card-bottom-up">
                            <span class="main-card-bottom-car">{{item.licensePlate ? item.licensePlate.substring(0,1)+"·" + item.licensePlate.substring(1,10) : ''}}</span>
                            <span class="main-card-bottom-time">续费时间 {{ item.payTime }}</span>
                        </div>
                        <div @click="handleDelete(item.id,item.licensePlate,item.parkingId)">
                            <img src="../assets/img/delete.png" class="main-card-bottom-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-interval"></div>
        </div>
        <!--        <div class="container-main">-->
        <!--        </div>-->
        <!--        <van-pull-refresh  v-model="refreshing" @refresh="onRefresh" loosing-text="释放刷新" pulling-text="下拉刷新" success-text="刷新成功">-->
        <!--            &lt;!&ndash;      offset="10"&ndash;&gt;-->
        <!--            <van-list-->
        <!--                    v-model="loading"-->
        <!--                    :finished="finished"-->
        <!--                    finished-text="没有更多啦"-->
        <!--                    :immediate-check="false"-->
        <!--                    @load="onLoad">-->
        <!--            </van-list>-->
        <!--        </van-pull-refresh>-->
        <div class="main-buttom">
            <van-button type="primary" v-if="btn" @click="handleAdd">+</van-button>
        </div>
        <!--        <NavBar></NavBar>-->
    </div>
</template>

<script>
    import {Button, Dialog, Empty, Icon, List, PullRefresh} from 'vant'
    import NavBar from "../components/NavBar"
    import {getIsSubscribe, longCar, longCarList} from "../api/interface";
    import Toast from "vant/lib/toast";

    export default {
        name: "index",
        components: {
            [Button.name]: Button,
            [Empty.name]: Empty,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Icon.name]: Icon,
            NavBar,
        },
        data() {
            return {
                show: false,
                btn: false,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                current: 1,
                size: 10,
                avatar: sessionStorage.getItem("avatar"),
                h: '',
                time: '',
                subscribe: '',
            }
        },
        created() {
            this.h = new Date().getHours();
            this.time = `${this.h <= 12 ? '上午好' : 12 < this.h < 19 ? '下午好' : '晚上好'}`;
        },
        mounted() {
            this.getLongCarList();
            if (this.list.length == null) {
                this.show = true;
                this.btn = false;
                document.querySelector('body').setAttribute('style', 'background-color:#F6F6F6' +
                    'background: #F6F6F6;')
            } else {
                document.querySelector('body').setAttribute('style', 'background-color:#F6F6F6')
                this.show = false;
                this.btn = true;
            }
        },
        methods: {
            getLongCarList() {
                longCarList().then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.list = res.data.data;
                        if (this.list.length == null) {
                            this.show = true;
                            this.btn = false;
                            document.querySelector('body').setAttribute('style', 'background-color:#fff')
                        } else {
                            document.querySelector('body').setAttribute('style', 'background-color:#F9F9F9')
                            this.show = false;
                            this.btn = true;
                        }
                    }
                })
                // this.current = 1;
                // this.refreshing = false;
                // this.loading = false;
            },
            // onLoad() {
            //     this.getLongCarList();
            // },
            // onRefresh() {
            //     this.getLongCarList();
            // },
            handleAdd() {
                this.$router.push('newly');
                getIsSubscribe(sessionStorage.getItem('openId')).then(res => {
                    this.subscribe = res.data.data;
                    if (res.data.data == 1) {
                    } else if (res.data.data == 0) {
                        Dialog.confirm({
                            title: '温馨提示',
                            message: '关注公众号“链路云停”才可绑定长租车，是否确定？',
                            confirmButtonColor: '#DC5040'
                        }).then(() => {
                            // on confirm
                            let url = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxMDI4MTcyNQ==#wechat_redirect";
                            window.open(url, '_blank');
                        }).catch(() => {
                            // on cancel
                        });
                    }
                })
            },
            longPayRecord(item) {
                this.$router.push({
                    path: "/longRent", query: {
                        id: item.id,
                        parkingId: item.parkingId,
                        parkingName: item.parkingName,
                        licensePlate: item.licensePlate,
                        // cycleType: item.cycleType,
                        // payTime: item.createTime,
                        // payMoney: item.payMoney,
                        // dueTime: item.dueTime,
                        // parkingPlaceNumber: item.parkingPlaceNumber
                    }
                })
            },
            handleDelete(id, licensePlate, parkingId) {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '是否确认删除长租车',
                    confirmButtonColor: '#DC5040',
                }).then(() => {
                    longCar(id, licensePlate, parkingId).then((res) => {
                        if (res.data.code === "SUCCESS") {
                            this.getLongCarList();
                            Toast(res.data.message);
                        }
                    })
                }).catch(() => {
                    Toast('取消删除');
                });
            }
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#F6F6F6')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            // to.meta.keepAlive = false;
            next()
        }
    }
</script>

<style scoped>
    .container-top {
        position: fixed;
        top: 0;
        z-index: 2;
    }

    .container_backgroundImg {
        width: 100%;
        display: block;
        position: fixed;
    }

    .container-top-content {
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100%;
        top: 13px;
    }

    .content-list {
        color: #fff;
        display: flex;
        justify-content: flex-start;
        line-height: 30px;
        margin: 0 10px;
    }

    .content-list-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    .content-list-greet {
        font-size: 18px;
        font-weight: 500;
    }

    .content-list-title {
        font-size: 12px;
        font-weight: 400;
    }

    .tab-img {
        position: fixed;
        z-index: 3;
        top: 130px;
    }

    .container-main-img {
        width: 100%;
        height: 28px;
    }

    .container-main {
        /*position: fixed;*/
        /*top: 136px;*/
    }

    .container-main-card {
        /*padding-top: 170px;*/
        position: absolute;
        top: 160px;
        z-index: 1;
    }

    .bottom-interval {
        width: 100%;
        height: 50px;
    }

    .main-card {
        background: linear-gradient(0deg, #FFFFFF, #D8E3E8);
        border-radius: 13px 13px 9px 9px;
        margin: 15px 10px;
    }

    .main-card-img {
        width: 100%;
        margin-top: -10px;
    }

    .main-card-container {
        display: flex;
        flex-direction: column;
    }

    .main-card-head {
        margin-top: -105px;
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        line-height: 25px;
    }

    .main-card-head-font {
        font-size: 18px;
        font-weight: 600;
        color: #222222;
        background: linear-gradient(0deg, #648DA0 0%, #355D79 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    .main-card-head-title {
        float: left;
        padding: 0 10px;
    }

    .haed-card-img {
        width: 17px;
        height: 17px;
        border-radius: 3px;
    }

    .main-card-head-time {
        padding: 0 10px;
    }

    .main-card-head-time-font {
        color: #648DA0;
        font-weight: 400;
        font-size: 13px;
        float: left;
    }

    .placeholder-div {
        background: #648DA0;
        height: 1px;
        opacity: 0.1;
    }

    .main-card-middle {
        padding: 13px 10px;
    }

    .main-card-middle-font {
        font-size: 15px;
        font-weight: 400;
        color: #355D79;
        float: left;
        padding: 0 10px;
    }

    .main-card-bottom {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .main-card-bottom-up {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        line-height: 25px;
    }

    .main-card-bottom-car {
        display: flex;
        font-size: 17px;
        color: #222222;
        font-weight: 500;
        padding: 0 10px;
    }

    .main-card-bottom-time {
        display: flex;
        font-size: 13px;
        color: #888888;
        font-weight: 500;
        padding: 0 10px;
    }

    .main-card-bottom-img {
        border: 1px solid #E4E5E6;
        border-radius: 50%;
        padding: 8px;
        width: 24px;
        height: 24px;
    }

    .main-buttom {
        display: block;
        bottom: 100px;
        right: 18%;
        position: fixed;
        z-index: 3;
    }

    .van-button {
        width: 60px;
        height: 60px;
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        box-shadow: 0px 3px 13px 0px rgba(112, 27, 10, 0.35);
        border-radius: 50%;
        font-size: 45px;
        font-weight: 500;
        border: none;
    }
</style>
