<template>
    <van-tabbar route active-color="#DC5040" inactive-color="#000">
        <van-tabbar-item v-if="this.$route.path != '/index'" replace to="/home" icon="search">临停</van-tabbar-item>
        <van-tabbar-item v-else replace to="/index" icon="home-o">长租</van-tabbar-item>
    </van-tabbar>
</template>
<script>
    import {Tabbar, TabbarItem} from 'vant';

    export default {
        name: "NavBar",
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
        }
    }
</script>

<style lang="less" scoped>

</style>
